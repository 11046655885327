<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Fee Heads
            <v-spacer></v-spacer>
            <add-button
              permission="fee-heads-read"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              >New Fee Head</add-button
            >
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            sort-by="title"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.title }}</td>
                <td class="text-xs-left">{{ item.description }}</td>
                <td
                  class="text-xs-right"
                  v-if="
                    item.title.toLowerCase() == 'advance' ||
                      item.title.toLowerCase() == 'previous due' ||
                      item.title.toLowerCase() == 'penalty' ||
                      item.title.toLowerCase() == 'transport'
                  "
                >
                  -
                </td>
                <td class="text-right" v-else>
                  <edit-button
                    permission="fee-heads-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="fee-heads-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>

          <!--<v-card-actions>-->
          <!--<v-spacer/>-->
          <!--<v-pagination v-if="form.items.data.length > 0" v-model="pagination.page"-->
          <!--:length="form.items.meta.last_page"></v-pagination>-->
          <!--<v-spacer/>-->
          <!--</v-card-actions>-->
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="300px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-text-field
                autocomplete="off"
                label="Fee Head Name*"
                required
                class="pa-0 pt-3"
                v-model="form.title"
                name="title"
                :error-messages="form.errors.get('title')"
                outlined
                dense
                :rules="[(v) => !!v || 'Title Field is required']"
              />
              <v-flex xs12>
                <v-textarea
                  outlined
                  dense
                  solo
                  name="description"
                  label="Description"
                  v-model="form.description"
                  :rules="[(v) => !!v || 'Description Field is required']"
                />
              </v-flex>
              <small>*indicates required field</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              outlined
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" outlined @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    form: new Form(
      {
        title: "",
        description: "",
      },
      "/api/fee-head"
    ),
    search: null,
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Title", align: "left", value: "title" },
      { text: "Description", align: "left", value: "description" },
      { text: "Action", align: "right", sortable: false, width: 250 },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString("withDefaultHeads=true")
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    save() {
      this.form.batch_id = this.batch.id;
      this.form.store().catch((e) => {
        this.$events.fire("notification", {
          message: e.data.message,
          status: "error",
        });
      });
    },

    setChart(data) {
      if (data == null) {
        data = this.form.items.data;
      }

      this.chartOptions.labels = data.map((item) => {
        return item.name;
      });

      this.chartData = data.map((item) => {
        return Math.floor(Math.random() * Math.floor(50));
      });
    },

    viewGrade(id) {
      this.$router.push({
        name: "grades-detail",
        params: {
          gradeId: id,
        },
      });
    },

    test() {},
  },
};
</script>
<style lang="scss">
/*.page-layout {*/

/*}*/
</style>
